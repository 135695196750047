<template>
    <section class="cursos container">
        <!-- content -->
        <div class="content text-center space-y-8" v-if="Object.keys(courses).length > 0">
            <div class="sectionTitle space-y-3">
                <h6 class="text-primary-700 font-bold md:text-left">Nuevos cursos</h6>
                <h2 class="text-gray-900 text-2xl md:text-3xl font-extrabold">{{ courses.title }}</h2>
                <h5 class="text-gray-900 font-semibold text-sm md:text-lg line-clamp-3" v-if="courses.subtitle">{{
                    courses.subtitle }}</h5>
            </div>
            <div class="text space-y-3 w-full md:w-1/2 mx-auto" v-html="courses.content"></div>
            <router-link class="block" :to="'/cursos/' + courses.slug">
                <div class="video w-full md:w-1/2 mx-auto aspect-video rounded-md bg-gray-200 overflow-hidden">
                    <img class="w-full h-full object-cover" v-if="Object.keys(courses.modules).length > 0"
                        :src="utilities.getYouTubeCover(courses.modules[0].link, 'max')" alt="">
                </div>
            </router-link>

            <div class="buttons flex flex-col-reverse md:flex-row gap-4 md:justify-center">
                <router-link to="/cursos">
                    <button class="py-3 px-5 rounded-md border border-gray-700 w-full md:w-fit">Ver todos los
                        cursos</button>
                </router-link>
                <router-link :to="'/cursos/' + courses.slug">
                    <button
                        class="bg-primary-900 border border-primary-900 py-3 px-5 rounded-md text-white w-full md:w-fit">Cursar
                        ahora</button>
                </router-link>
            </div>
        </div>

        <!-- skeleton -->
        <div class="content text-center space-y-12 animate-pulse" v-else>
            <div class="sectionTitle flex flex-col gap-5 items-center">
                <div class="sectionTitle w-full flex justify-center md:justify-start">
                    <div class="sectionTitle w-32 h-6 bg-gray-300 rounded-md"></div>
                </div>
                <div class="w-full md:w-1/2 h-12 mb-1 bg-gray-300 rounded-md"></div>
                <div class="w-32 h-7 bg-gray-300 rounded-md"></div>
            </div>
            <div class="text w-full space-y-1 flex flex-col items-center">
                <div class="textSkeleton w-full md:w-1/2 h-5 bg-gray-300 rounded-md"></div>
                <div class="textSkeleton w-full md:w-1/2 h-5 bg-gray-300 rounded-md"></div>
                <div class="textSkeleton w-1/2 md:w-1/4 h-5 bg-gray-300 rounded-md"></div>
            </div>
            <div class="video w-full md:w-1/2 mx-auto aspect-video rounded-md bg-gray-300"></div>
            <div class="buttons flex flex-col md:flex-row gap-4 md:justify-center">
                <div class="button w-full md:w-48 h-[50px] bg-gray-300 rounded-md"></div>
                <div class="button w-full md:w-32 h-[50px] bg-gray-300 rounded-md"></div>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
//utilities
import utilities from '../../utilities'

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'HomeCoursesComponent',
    computed: {
        utilities: () => {
            return utilities
        }
    },
    props: {
        courses: {
            type: Object,
            default: () => ({})
        }
    }
})
</script>