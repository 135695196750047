<template>
    <section class="about container">
        <!-- Content -->
        <div class="content flex flex-col md:flex-row gap-8 items-center" v-if="Object.keys(about).length > 0">
            <div class="cover w-full md:w-[45%] aspect-square bg-gray-200 rounded-xl overflow-hidden">
                <img class="w-full h-full object-cover" v-if="about.cover && about.cover.type != 'video/mp4'" :src="about.image.data.thumbnails[8].url" alt="">
                <video id="player"
                    class="w-full h-full object-cover"
                    autoplay
                    loop
                    no-controls
                    muted
                    playsinline
                    data-wf-ignore="true"
                    data-object-fit="cover"
                    preload="auto" v-else>
                    <source :src="about.cover.data.full_url" type="video/mp4" />
                </video>
            </div>
            <div class="text w-full md:w-[55%] space-y-8 text-center md:text-left">
                <div class="title space-y-3">
                    <h6 class="text-primary-700 font-bold">¿Quiénes somos?</h6>
                    <h2 class="w-3/4 md:w-full text-2xl md:text-3xl text-gray-900 font-extrabold mx-auto">{{ about.title }}</h2>
                </div>
                <div class="text space-y-5" v-html="about.content"></div>
                <router-link to="/contacto" class="block">
                    <button class="w-full md:w-fit bg-primary-900 py-3 px-5 rounded-md text-white">Contactanos</button>
                </router-link>
            </div>
        </div>
        <!-- Skeleton -->
        <div class="aboutSkeleton flex flex-col md:flex-row gap-8 items-center animate-pulse" v-else>
            <div class="img w-full md:w-[45%] aspect-square bg-gray-300 rounded-xl"></div>
            <div class="text w-full md:w-[55%] space-y-10">
                <div class="title space-y-3 flex flex-col items-center md:items-start">
                    <div class="sectionTitle w-32 h-6 bg-gray-300 rounded-md"></div>
                    <div class="title w-full md:w-1/2 h-8 bg-gray-300 rounded-md"></div>
                </div>
                <div class="text w-full space-y-6">
                    <div class="text space-y-3">
                        <div class="textSkeleton w-full h-5 bg-gray-300 rounded-md"></div>
                        <div class="textSkeleton w-full h-5 bg-gray-300 rounded-md"></div>
                        <div class="textSkeleton w-1/2 h-5 bg-gray-300 rounded-md"></div>
                    </div>
                    <div class="text space-y-3">
                        <div class="textSkeleton w-full h-5 bg-gray-300 rounded-md"></div>
                        <div class="textSkeleton w-full h-5 bg-gray-300 rounded-md"></div>
                        <div class="textSkeleton w-1/2 h-5 bg-gray-300 rounded-md"></div>
                    </div>
                </div>

                <div class="buttonSkeleton w-full md:w-64 h-12 bg-gray-300 rounded-md"></div>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
//plyr
/*import Plyr from 'plyr'
import 'plyr/dist/plyr.css'*/

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'HomeAboutComponent',
    props: {
        about: {
            type: Object,
            default: () => ({})
        }
    },
    mounted() {
        /*new Plyr('#player',{
            controls: ['mute']
        });*/
    }
})
</script>