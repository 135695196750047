<template>
    <div class="rounded-t-xl overflow-hidden" :class="type == 'entrepreneurship' || type == 'module' ? 'shadow-md rounded-b-xl' : ''">
        <!-- content -->
        <div class="content" v-if="Object.keys(data).length > 0">
            <!-- card image -->
            <div class="cardImg relative bg-gray-100 aspect-video">
                <!-- Date tag -->
                <div class="relativeTime absolute py-2 px-3 top-3 right-3 rounded-full text-xs font-medium"
                    v-if="showDateTag"
                    :class="getDateTag() ? 'bg-primary-900 text-white' : 'bg-white text-primary-900'">{{ getDateTag() ?
                        'Próximo Evento' : 'Evento pasado' }}</div>
                <img :src="(type && ['course', 'module', 'aDayIn'].includes(type)) ? data.cover : data.cover.data.thumbnails[7].url" alt="Portada" class="w-full h-full object-cover transition-opacity duration-500" :class="imageLoaded ? 'opacity-100' : 'opacity-0'" @load="onImgLoad">
            </div>

            <!-- card body (Event, Course, Module, Entrepreneurship, Interview, Internship) -->
            <div class="cardBody space-y-4" :class="type == 'entrepreneurship' || type == 'module' ? 'p-5' : 'pt-5'"
                v-if="Object.keys(data).length > 0">
                <!-- title -->
                <h3 class="text text-gray-900 text-lg font-semibold truncate">{{ data.title }}</h3>
                
                <!-- description -->
                <div class="description text-gray-500"
                    :class="type == 'entrepreneurship' ? 'line-clamp-6 h-36' : 'line-clamp-2'"
                    v-html="getDescription()"
                    v-if="type != 'module'"></div>
                
                <!-- button -->
                <div class="button w-fit bg-primary-50 py-3 px-5 rounded-md text-primary-900 font-semibold"
                    v-if="type && ['event', 'course', 'internship', 'inspiration', 'aDayIn'].includes(type)">{{ (type == 'course') ? 'Cursar' : 'Saber más' }}</div>

                <!-- Entrepreneurship Info -->
                <div class="entrepreneurshipInfo space-y-4" v-if="type == 'entrepreneurship'">
                    <!-- Web page -->
                    <div class="web flex gap-1 items-center">
                        <IconComponent color="bg-primary-900" width="w-6" url="/img/icons/web-icon.svg"></IconComponent>
                        <p class="text-gray-700" @click="goToLink(data.website)"
                            :class="data.website ? 'cursor-pointer' : ''">{{ data.website ? 'Visitar web' : '-' }}</p>
                    </div>
                    <!-- Location -->
                    <div class="location flex gap-1 items-center">
                        <IconComponent color="bg-primary-900" width="w-6" url="/img/icons/location-icon.svg">
                        </IconComponent>
                        <p class="text-gray-700">{{ data.location ? data.location : '-' }}</p>
                    </div>
                    <!-- Redes sociales -->
                    <div class="socialNetworks flex gap-6 items-center">
                        <!-- facebook -->
                        <IconComponent :class="data.facebook ? 'opacity-100 cursor-pointer' : 'opacity-20'"
                            color="bg-primary-900" width="w-6" url="/img/icons/facebook-icon.svg"
                            @click="goToLink(data.facebook)"></IconComponent>
                        <!-- instagram -->
                        <IconComponent :class="data.instagram ? 'opacity-100 cursor-pointer' : 'opacity-20'"
                            color="bg-primary-900" width="w-6" url="/img/icons/instagram-icon.svg"
                            @click="goToLink(data.instagram)"></IconComponent>
                        <!-- tiktok -->
                        <IconComponent :class="data.tiktok ? 'opacity-100 cursor-pointer' : 'opacity-20'"
                            color="bg-primary-900" width="w-6" url="/img/icons/tiktok-icon.svg"
                            @click="goToLink(data.tik_tok)"></IconComponent>
                        <!-- whatsapp-->
                        <IconComponent :class="data.whatsapp ? 'opacity-100 cursor-pointer' : 'opacity-20'"
                            color="bg-primary-900" width="w-6" url="/img/icons/whatsapp-icon.svg"
                            @click="goToLink(data.whatsapp)"></IconComponent>
                    </div>
                </div>
            </div>
        </div>

        <!-- skeleton -->
        <div class="skeleton" v-if="skeleton">
            <!-- card image -->
            <div class="cardImg aspect-video animate-pulse bg-gray-300">
            </div>
            <!-- card body (Event, Course, Module, Entrepreneurship, Interview, Internship) -->
            <div class="cardBody space-y-4" :class="type == 'entrepreneurship' ? 'p-5' : 'pt-5'">
                <!-- title -->
                <h3 class="text text-gray-900 text-lg font-semibold"></h3>

                <!-- description -->
                <div class="description text-gray-500"
                    :class="type == 'entrepreneurship' ? 'line-clamp-6 h-36' : 'line-clamp-2'" v-if="type != 'module'">
                </div>

                <!-- button -->
                <button class="button bg-primary-50 py-3 px-5 rounded-md text-primary-900 font-semibold"
                    v-if="type && ['event', 'course', 'internship', 'inspiration', 'aDayIn'].includes(type)"></button>
                
                <!-- Entrepreneurship Info -->
                <div class="entrepreneurshipInfo space-y-4" v-if="type == 'entrepreneurship'">
                    <!-- Web page -->
                    <div class="web flex gap-1 items-center">
                        <IconComponent color="bg-primary-900" width="w-6" url="/img/icons/web-icon.svg"></IconComponent>
                        <p class="text-gray-700" @click="goToLink(data.website)"
                            :class="data.website ? 'cursor-pointer' : ''">{{ data.website ? 'Visitar web' : '-' }}</p>
                    </div>

                    <!-- Location -->
                    <div class="location flex gap-1 items-center">
                        <IconComponent color="bg-primary-900" width="w-6" url="/img/icons/location-icon.svg">
                        </IconComponent>
                        <p class="text-gray-700">{{ data.location ? data.location : '-' }}</p>
                    </div>

                    <!-- Redes sociales -->
                    <div class="socialNetworks flex gap-6 items-center">
                        <!-- facebook -->
                        <IconComponent :class="data.facebook ? 'opacity-100 cursor-pointer' : 'opacity-20'"
                            color="bg-primary-900" width="w-6" url="/img/icons/facebook-icon.svg"
                            @click="goToLink(data.facebook)"></IconComponent>
                        <!-- instagram -->
                        <IconComponent :class="data.instagram ? 'opacity-100 cursor-pointer' : 'opacity-20'"
                            color="bg-primary-900" width="w-6" url="/img/icons/instagram-icon.svg"
                            @click="goToLink(data.instagram)"></IconComponent>
                        <!-- tiktok -->
                        <IconComponent :class="data.tiktok ? 'opacity-100 cursor-pointer' : 'opacity-20'"
                            color="bg-primary-900" width="w-6" url="/img/icons/tiktok-icon.svg"
                            @click="goToLink(data.tik_tok)"></IconComponent>
                        <!-- whatsapp-->
                        <IconComponent :class="data.whatsapp ? 'opacity-100 cursor-pointer' : 'opacity-20'"
                            color="bg-primary-900" width="w-6" url="/img/icons/whatsapp-icon.svg"
                            @click="goToLink(data.whatsapp)"></IconComponent>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
//Components
import IconComponent from './IconComponent.vue';

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'CardComponent',
    components: {
        IconComponent
    },
    data(){
        return{
            imageLoaded: false
        }
    },
    props: {
        skeleton: {
            type: Boolean,
            default: false
        },
        type: String,
        showDateTag: {
            type: Boolean,
            default: false
        },
        data: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        onImgLoad () {
            this.imageLoaded = true
        },
        getDescription() {
            if (this.data.description) {
                return this.data.description
            }
            else if (this.data.excerpt) {
                return this.data.excerpt
            }
            else if (this.data.content) {
                return this.data.content
            }
            else if (this.data.short_description) {
                return this.data.short_description
            }
            
        },
        getDateTag() {
            //set today date
            let fechaActual = new Date()
            fechaActual.setDate(fechaActual.getDate() - 1)

            //set event date
            let fechaEvento = new Date(this.data.date_from)

            //get dateTag
            if (fechaActual > fechaEvento) {
                return false
            }
            else {
                return true
            }
        },
        goToLink(url: string) {
            if (url) {
                window.open(url, '_blank')
            }
        }
    }
})
</script>