import { createStore } from 'vuex'

//vuex persist
import vuexPersistance from 'vuex-persist';

export default createStore({
  state: {
    user: [] as any[any],
    token: null,
    config: [] as any[any],
    mobile: false
  },
  getters: {
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setUserData(state, userData) {
      state.user = userData
    },
    clearUserData(state) {
      state.user = []
      state.token = null
    },
    setConfig(state, config){
      state.config = config
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    new vuexPersistance({
      storage: window.localStorage
    }).plugin
  ]
})
