<template>
    <footer class="bg-[#1D2939] text-white text-sm">
        <div class="container mx-auto py-12 md:py-16 space-y-8 md:space-y-16">
            <div class="footerContent flex flex-col md:flex-row justify-between gap-12 md:gap-16">
                <div class="logo h-16 md:h-20 flex-none">
                    <img src="/img/logo.svg" alt="logo" class="h-full mx-auto">
                </div>
                <div class="contactInfo space-y-4 text-center md:text-left">
                    <h5 class="font-black">Contactanos</h5>
                    <a class="block" :href="$store.state.config.contact" v-if="$store.state.config.contact">Envianos un mensaje</a>
                </div>
                <div class="socialNetworks space-y-4 w-[220px] text-center md:text-left mx-auto">
                    <h5 class="font-black">Seguinos en las redes sociales de New Holland</h5>
                    <div class="socialNetworksIcons flex gap-6">
                        <a :href="$store.state.config[item.name]" v-for="item, index in social" :key="index" v-show="$store.state.config[item.name]">
                            <div class="icon w-6 h-6 bg-white cursor-pointer"
                                :style="'-webkit-mask-image: url(' + item.icon + ' ); -webkit-mask-repeat: no-repeat; -webkit-mask-position: center; -webkit-mask-size: contain;'">
                            </div>
                        </a>
                    </div>
                </div>
                <div class="newsLetter space-y-4 w-full md:w-1/3 md:flex-0">
                    <h5 class="font-black text-center md:text-left">Suscribite a nuestro newsletters</h5>
                    <div class="form flex flex-col md:flex-row gap-2">
                        <input type="email" class="w-full rounded-lg px-4 text-gray-900 h-12"
                            placeholder="Escribe tu email">
                        <button
                            class="bg-primary-50 py-3 px-5 rounded-md text-primary-900 font-semibold text-base">Suscribirme</button>
                    </div>
                </div>
            </div>
            <div
                class="footerBottom flex flex-col gap-12 md:gap-0 md:flex-row justify-between pt-8 md:pt-12 border-t border-white text-sm">
                <div class="left flex flex-col md:flex-row gap-4 md:gap-8 items-center">
                    <!--p>Términos y condiciones</p-->
                    <router-link to="/legal/#cookies">
                        <p>Aviso de cookies</p>
                    </router-link>
                    <router-link to="/legal/#privacidad">
                        <p>Políticas de privacidad</p>
                    </router-link>
                </div>
                <div class="right flex flex-col md:flex-row gap-4 items-center">
                    <p>© 2024. Todos los derechos reservados</p>
                    <img src="/img/logo_newholland.svg" alt="Logo" class="h-6">
                </div>
            </div>
        </div>
    </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'FooterComponent',
    data() {
        return {
            social: [
                {
                    name: 'x',
                    icon: '/img/icons/x-twitter-icon.svg'
                },
                {
                    name: 'facebook',
                    icon: '/img/icons/facebook-icon.svg'
                },
                {
                    name: 'instagram',
                    icon: '/img/icons/instagram-icon.svg'
                },
                {
                    name: 'tik_tok',
                    icon: '/img/icons/tiktok-icon.svg'
                },
                {
                    name: 'youtube',
                    icon: '/img/icons/youtube-icon.svg'
                }
            ]
        }
    }
})
</script>