<template>
    <div class="sideBar w-full h-screen fixed top-0 bg-white z-30 transition-all duration-300" :class="(isOpen) ? 'left-0' :'-left-full'">
        <slot></slot>
    </div>
</template>

<script lang="ts">
//This Component
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'SideBar',
    props:{
        isOpen: Boolean
    }
})
</script>