<template>
    <div class="icon aspect-square" :class="width + ' ' + color"
        :style="'-webkit-mask-image: url(' + url + '); -webkit-mask-repeat: no-repeat; -webkit-mask-position: center; -webkit-mask-size: contain;'">
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: "IconComponent",
    props: {
        url: String,
        color: {
            type: String,
            default: 'bg-black'
        },
        width: {
            type: String,
            default: 'w-full'
        }
    }
})
</script>