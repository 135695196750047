<template>
  <div class="home">
    <!-- Hero -->
    <div class="hero aspect-[3/1] bg-gray-200" v-if="!$store.state.mobile">
      <img class="w-full h-full object-cover object-center"
        v-if="$store.state.config.main_banner && $store.state.config.main_banner.type != 'video/mp4'"
        :src="$store.state.config.main_banner.data.thumbnails[8].url" alt="">
      <video id="mainBanner" class="w-full h-full object-cover object-center" autoplay loop no-controls muted playsinline preload="auto"
        data-wf-ignore="true" data-object-fit="cover" v-else-if="$store.state.config.main_banner">
        <source :src="$store.state.config.main_banner.data.full_url" type="video/mp4">
      </video>
    </div>

    <div class="pt-4 pb-8 md:py-16 space-y-16">
      <!-- ABOUT -->
      <HomeAboutComponent :about="about"></HomeAboutComponent>

      <!-- COURSES -->
      <HomeCoursesComponent :courses="courses"></HomeCoursesComponent>

      <!-- INSPIRATION -->
      <section class="inspiration space-y-8">
        <!-- title -->
        <div class="sectionTitle container">
          <h6 class="text-primary-700 font-bold text-center md:text-left">Inspiración</h6>
        </div>

        <!-- content -->
        <div class="content" v-if="interviews.length > 0">
          <div class="swiperContainer relative overflow-hidden">
            <div class="inspirationSwiper container">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="interview in interviews" :key="interview.id">
                  <router-link :to="'/inspiracion/historia-de-mujeres/' + interview.slug">
                    <CardBig :data="interview" type="interview" :img="interview.cover.data.thumbnails[7].url">
                    </CardBig>
                  </router-link>
                </div>
              </div>
              <!-- navigation -->
              <div class="swiper-button-prev !hidden md:!flex"></div>
              <div class="swiper-button-next !hidden md:!flex"></div>
            </div>
          </div>
          <!-- pagination-->
          <div class="swiper-pagination my-6"></div>
          <!-- button -->
          <router-link class="block container" to="/inspiracion/historia-de-mujeres">
            <button class="w-full md:w-fit bg-primary-900 py-3 px-5 rounded-md text-white block mx-auto">Ver más
              historias</button>
          </router-link>
        </div>

        <!-- skeleton -->
        <div class="container" v-else>
          <!-- card -->
          <CardBig type="interview"></CardBig>
          <!-- pagination -->
          <div class="pagination w-full h-6 flex items-center justify-center my-6">
            <div class="paginationBullet w-2 h-2 bg-gray-300 rounded-full"></div>
          </div>
          <!-- button -->
          <div class="button w-52 h-12 bg-gray-300 mx-auto rounded-md"></div>
        </div>
      </section>

      <!-- Events -->
      <section class="eventsSection container space-y-8">
        <div class="sectionTitle flex flex-col items-center gap-3">
          <h6 class="text-primary-700 font-bold">Últimos eventos</h6>
          <h2 class="w-full md:w-2/5 text-gray-900 font-extrabold text-2xl md:text-3xl text-center">No te pierdas nuestra agenda de
            Mujeres en Campaña</h2>
        </div>
        <!-- content -->
        <div class="events grid grid-cols-1 md:grid-cols-3 gap-8" v-if="events.length > 0">
          <router-link :to="'/eventos/' + event.slug" v-for="event, index in events" :key="index">
            <CardComponent :data="event" type='event' :showDateTag="true" />
          </router-link>
        </div>
        <!-- skeleton -->
        <div class="events grid grid-cols-1 md:grid-cols-3 gap-8" v-else>
          <CardComponent v-for="i in 3" :key="i" />
        </div>
      </section>
    </div>
  </div>
</template>

<script lang="ts">
//components
import CardComponent from '@/components/CardComponent.vue';
import CardBig from '@/components/CardBig.vue';
import HomeAboutComponent from '@/components/home/about.vue'
import HomeCoursesComponent from '@/components/home/courses.vue';

//utilities
import utilities from '../utilities'

// Swiper
import { Swiper } from 'swiper';
import { Navigation, Pagination } from 'swiper/modules'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    CardComponent,
    CardBig,
    HomeAboutComponent,
    HomeCoursesComponent
  },
  data() {
    return {
      about: [] as any[any],
      courses: [] as any[any],
      interviews: [] as any[any],
      events: [] as any[any]
    }
  },
  async created() {
    //get about
    this.about = await utilities.getItems('/items/about/1?fields=*,cover.type,cover.data')

    //get courses
    this.courses = await utilities.getItems('/items/courses?fields=*,modules.*&limit=1&sort=-created_on')
    this.courses = this.courses[0]

    //get events
    const events = await utilities.getItems('/items/events?fields=*,cover.data&limit=3&sort=-date_from')

    events.forEach((event: any) => {
      event.title = event.name
      this.events.push(event)
    })
  },
  async mounted() {
    //get interviews
    this.interviews = await utilities.getItems('/items/articles?fields=*,cover.data&filter[section][eq]=story&sort=-created_on&limit=3')

    this.$nextTick(() => {
      const inspirationSwiper = new Swiper('.inspirationSwiper', {
        slidesPerView: 1,
        spaceBetween: 24,
        centeredSlides: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination'
        },
        modules: [Navigation, Pagination]
      })
    })
  }
});
</script>

<style scoped>
.swiper-pagination {
  position: relative;
  top: initial;
  left: initial;
  bottom: initial;
}

.swiper-button-prev {
  left: 0px;
  border-radius: 0 64px 64px 0;
  padding: 8px 8px 8px 0;
}

.swiper-button-next {
  right: 0px;
  border-radius: 64px 0 0 64px;
}

.swiper-button-prev,
.swiper-button-next {
  z-index: 10;
  background-color: white;
  width: 50px;
  height: 64px;
  padding: 8px;
  border: 1px solid lightgray;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3);
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 16px;
  color: #1C4ED8;
}
</style>
